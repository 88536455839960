<template>
  <div class="gris mt-3">
    <section style="padding-bottom: 30px">
      <!-- Purple Header -->
      <mdb-edge-header class="main-2" />

      <!-- Card Container -->
      <mdb-container free-bird>
        <mdb-row>
          <mdb-col
            md="12"
            lg="12"
            class="mx-auto float-none my-red-4 white-text z-depth-1 py-2 px-2"
          >
            <mdb-card-body>
              <h1 v-if="city" class="font-weight-bold pt-3 text-center">
                En Cercas y Mallas Versátiles ponemos a sus órdenes nuestros
                servicios en la ciudad de {{ city }}:
              </h1>
              <h1 v-else class="font-weight-bold pt-3 text-center">
                En Cercas y Mallas Versátiles ponemos a sus órdenes nuestros
                servicios:
              </h1>
              <!--Body-->
              <div class="mt-5">
                <h6 class="h6-big">
                En Cercas y Mallas Versátiles nos dedicamos a la VENTA, INSTALACION, MANTENIMIENTO y REPARACION de todo tipo de Cercas de Malla Galvanizada o Ciclónica, Cercas de Alambre, Cercas Energizadas, Cercas de Cuchillas, Cercas de Púas, Cercas de Seguridad, Cercas de Reja Versátil, Cercas Ganaderas, Accesos, Jaulas de todo Tipo, Portones Automáticos
Residenciales e Industriales, Toldos, Sombras con Malla, Carpas y Lonas.
              </h6>
              </div>
              <div class="row d-flex align-items-center">
                <div class="col-md-8">
                  <h6 class="h6-big">
                    Si necesita un producto nuevo... se lo Instalamos.
                  </h6>
                  <h6 class="h6-big">
                    Si necesita Mantenimiento a un producto que está en
                    servicio... le damos Mantenimiento
                  </h6>
                  <h6 class="h6-big">
                    Si necesita reparación de un producto desdompuesto... se lo
                    Reparamos.
                  </h6>
                  <h6 class="h6-big">
                    Pida de inmediato un presupuesto SIN COSTO llamando al
                    teléfono 800 727 9437 tenga la seguridad que será atendido a
                    la voz de ya!!!
                  </h6>
                </div>
                <div class="col-md-4">
                  <div class="container d-flex align-items-center py-3">
                    <video
                      class="video-intro"
                      autoPlay
                      controls
                      style="width: 100%"
                    >
                      <source src="../assets/servicios2.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div class="text-center">
                  <h5 class="mx-auto mb-3 arr">
                    Protección y seguridad para tu hogar
                  </h5>
                  <h5 class="mx-auto mb-3 arr">
                    Protege tu hogar o patrimonio
                  </h5>
                </div>
              </div>
            </mdb-card-body>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      <!-- /.Card Container -->
    </section>
   <h4 class="text-center my-5 red-text font-weight-bold pb-4 my-h4">
        "En nuestros cercos mejoramos cualquier presupuesto"
      </h4>
    <mdb-container class="pb-5">
      <mdb-row>
        <mdb-col md="12"
            lg="12"
            class="mx-auto float-none my-red-4 z-depth-1 py-2 px-2">
          <div class="">
      <router-link to="/productos/Mallas">
        <mdb-card class="mb-5 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Mallas</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/1.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/11.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/9.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/6.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/10.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/2.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/productos/Mallas" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
      <router-link to="/producto/13">
        <mdb-card class="mb-5 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Concertina</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/concertina/1.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/concertina/2.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/concertina/3.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/concertina/4.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/concertina/5.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/concertina/6.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/producto/13" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
      <router-link to="/producto/28">
        <mdb-card class="mb-4 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Rejas de acero</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/rejadeacero/1.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/rejadeacero/2.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/rejadeacero/3.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/rejadeacero/4.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/rejadeacero/5.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/rejadeacero/6.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/producto/28" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
      <router-link to="/producto/36">
        <mdb-card class="mb-5 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Cinta plástica para Malla</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-3">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/cinta.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-3">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/cinta2.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-3">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/cinta4.png')"
                  style="max-height: 80%"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-3">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mallas/cinta3.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/producto/36" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
      <router-link to="/productos/Tuberia">
        <mdb-card class="mb-5 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Tuberia para cercas</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mamposteria/3.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mamposteria/2.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mamposteria/7.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mamposteria/8.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mamposteria/9.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/mamposteria/12.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/productos/Tuberia" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
      <router-link to="/producto/37">
        <mdb-card class="mb-5 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Gaviones</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/gaviones/1.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/gaviones/2.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/gaviones/3.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/gaviones/4.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/gaviones/6.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/gaviones/5.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/producto/37" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
      <router-link to="/follaje">
        <mdb-card class="mb-5 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Follaje artificial</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/2.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/4.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/6.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/9.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/10.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/follaje/follaje/8.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/follaje" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
       <router-link to="/producto/19">
        <mdb-card class="mb-5 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Láminas</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/laminas/6.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/laminas/4.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/laminas/8.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/laminas/9.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/laminas/1.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/laminas/3.jpeg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/producto/19" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
      <router-link to="/maderaplas">
        <mdb-card class="mb-4 mt-4 mx-2 hoverable" style="background: #1c2331" data-aos="zoom-in-down">
          <mdb-card-body>
            <div class="text-center white-text mb-3">
              <h2>Maderaplas</h2>
            </div>
            <div class="row pb-2">
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/madera/madera01.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/madera/madera002.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/madera/madera003.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/madera/madera004.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/madera/madera009.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
              <div class="col-md-2">
                <mdb-view
                  class="zoom overlay z-depth-1 mb-4"
                  :src="require('../assets/madera/madera016.jpg')"
                  alt="zoom"
                >
                  <mdb-mask flex-center text="Ver Productos" />
                </mdb-view>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link to="/maderaplas" style="color: white"
                ><mdb-btn color="danger" rounded size="sm"
                  >Ver Especificaciones<mdb-icon
                    icon="arrow-right"
                    class="ml-2" /></mdb-btn
              ></router-link>
            </div>
          </mdb-card-body>
        </mdb-card>
      </router-link>
    </div>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <a target="_blank" href="https://api.whatsapp.com/message/OM43BU5OG5GNE1"
      ><mdb-btn
        color="succes"
        class="gradient-red-2 text-white"
        rounded
        id="fixedbutton"
        ><strong
          ><p class="mb-0">Solicitar</p>
          presupuesto</strong
        ></mdb-btn
      ></a
    >
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbIcon,
  mdbBtn,
  mdbEdgeHeader,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbMask
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbIcon,
    mdbBtn,
    mdbEdgeHeader,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbMask
  },
  mounted() {
    document.title = 'Cercas y Mallas Versatiles - Servicios'
    if (this.$route.params.city) {
      this.city = this.$route.params.city
      document.title = `Cercas y Mallas Versatiles - Servicios en ${this.$route.params.city}`
    }
  },
  data () {
    return {
      city: ''
    }
  }
};
</script>

<style>
.main-2 {
  background: no-repeat center fixed;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../assets/malla-main-2.jpg");
}
.gris {
  background: #1c2331
}
.blue-1 {
  text-shadow: 2px 2px 8px #45526e;
}
@keyframes pulse {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.arr {
  -webkit-animation: pulse 3s infinite ease-in-out;
  -moz-animation: pulse 3s infinite ease-in-out;
  -o-animation: pulse 3s infinite ease-in-out;
  animation: pulse 3s infinite ease-in-out;
}
.h6-big {
  font-size: 1.1em;
}
.my-red-4 {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
h4.my-h4 {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  padding: 15px 20px;
  position: relative;
}
h4.my-h4:after {   
  bottom: 0; 
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: rgb(214, 9, 9);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
h4.my-h4:hover:after { 
  width: 70%; 
  left: 15%; 
}

</style>